import Cookies from 'js-cookie'

const state={
    
}

const mutations={
    
}

const actions={
    
}

export default {
    namespaced: true,
    state:state,
    mutations:mutations,
    actions:actions
}
