import h from "./http/http";

const http = h.http
const get = h.get
const post = h.post

const sendManage = {
    // 送审论文管理论文视角
    getP2pPaper(params) {
        return get("/sending/p2p/paper",params);
    },
    // 送审论文管理专家视角
    getP2pResult(params){
        return get('/sending/p2p/result',params);
    },
    // 获取论文列表
    getPaperList(params)
    {
        return get("/school/getPaperList",params);
    },
    // 生成评阅书zip
    getReviewsZip(params) {
        return get('/school/generate_reviewsZip', params);
    },
    // 下载zip
    getZip(params) {
        return http.get('/sending/download_zip', {
            params: params,
            responseType: 'blob'
        });
    },
    // 下载评审信息表
    getStatisticsTable(params) {
        return http.post('/school/generateStatisticsTable', params);
    },

    // 导出table
    getTables(params) {
        return http.get('/sending/download_excel', {
            params: params,
            responseType: 'blob'
        });
    },
    // 获取送审规则
    getSendingRules(params) {
        return get('/school/getSendingRules', params);
    },
    // 下载评审信息表
    downloadSchoolExcel(params){
        return http.post('/excel/downloadSchoolExcel',params,{
            responseType: 'blob'
        });
    }
}

export default sendManage