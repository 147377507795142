import h from "./http/http";

const http = h.http
const get = h.get
const post = h.post

const user = {
    login(params) {
        return http.post('/login', params);
    },
    // 退出
    logout() {
        return post('/logout');
    },
    // 修改密码
    postEditPwd(params)
    {
        return post('/user/updateUserPassword',params);
    },
    getInstitution(){
        return get("/school/getInstitution");
    },
    // 点击接审链接
    getToken(params) {
        return get('/token', params);
    },
}

export default user