const state = {
    batch: ''
};
const mutations = {
    changeBatch(state, value) {
        state.batch = value;
    },
};
const actions = {
    changeBatch(context, value) {
        context.commit('changeBatch', value);
    },
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions
}