import h from "./http/http";

const http = h.http
const get = h.get
const post = h.post

const file = {
   // 导出table
   getTables(params) {
    return http.get('/sending/download_excel', {
        params: params,
        responseType: 'blob'
    });
},
}

export default file