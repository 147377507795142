import h from "./http/http";

const http = h.http
const get = h.get
const post = h.post

const reviewIndex = {
    getCheckReviews(params) {
        return get("/school/getCheckedReviews", params);
    },
    // 下载评审信息表
    getStatisticsTable(params) {
        return http.post('/school/generateStatisticsTable', params);
    },

    // 导出table
    getTables(params) {
        return http.get('/sending/download_excel', {
            params: params,
            responseType: 'blob'
        });
    },

     // 生成评阅书zip
     getReviewsZip(params) {
        return get('/sending/generate_reviewsZip', params);
    },
    // 下载zip
    getZip(params) {
        return http.get('/sending/download_zip', {
            params: params,
            responseType: 'blob'
        });
    },
}

export default reviewIndex