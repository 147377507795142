import h from "./http/http";

const http = h.http
const get = h.get
const post = h.post

const paperLibrary = {
    // 创建批次
    postBatchCrate(params) {
        return post("/school/batch/create", params);
    },
    // 获取送审规则
    getSendingRuleList() {
        return get("/school/get_sendingRuleList");
    },
    // 获取所有批次预览
    getAllBatch() {
        return get("/school/getAllBatch");
    },
    //获取层级学科
    getAllSubject() {
        return get("/school/get_AllSubject")
    },
    //获取单篇论文基本信息
    getPaperBasicInfo(params) {
        return get("/paper/get_paperBasicInfo", params)
    },
    // 获取原文库论文
    getOriginalPaperPreviews(params){
        return get("/paper/get_originalPaperPreviews",params)
    },
    // 上传学生名单
    postUploadStudent(params)
    {
        return post('/school/uploadStudent',params);
    },
    // 院校端提交送审
    getSubmit(params)
    {
        return get('/paper/school/submit',params);
    },
    // zxr 下载附件
    downloadFile(params){
        // return get('/paper/downloadPaperAnnex',params);
        return http.get('/paper/downloadPaperAnnex',{
            // paperId: params.paperId,
            params: {paperId:params.paperId},
            responseType: 'blob'
        });
    }
}

export default paperLibrary