import h from "./http/http";

const http = h.http
const get = h.get
const post = h.post

const expertInfo = {
    //获取本地专家库列表
    getLocalExpert(params){
        return get("/school/get_localExpert",params);
    },
    // 修改专家信息
    postModifyLocalExpertInfo(params)
    {
        return post("/school/modify_localExpertInfo",params);
    },
    //新增本地专家
    postAddLocalExpert(params)
    {
        return post('/school/add_localExpert',params);
    },
    // 删除专家
    postDeleteLocalExpert(params)
    {
        return post('/school/delete_localExpert',params);
    }
}

export default expertInfo