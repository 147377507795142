<template>
    <div class="sidebar-logo-container" :class="{'collapse':collapse}">
        <transition name="sidebarLogoFade">
            <router-link v-if='collapse' :key="collapse" class="sidebar-logo-link" to="/paper/batchManage">
                <img :src="require('@/assets/img/gui.png')" class="min-sidebar-logo">
                <!-- <h1 class="sidebar-title">{{min_title}}</h1> -->
            </router-link>
            <router-link v-else :key="collapse" class="sidebar-logo-link" to="/paper/batchManage">
                <img :src="require('@/assets/img/gui.png')" class="sidebar-logo">
                <!-- <h1 class="sidebar-title">{{title}}</h1> -->
            </router-link>
        </transition>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                logo: 'https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png',
                title: '江苏高擎论文评审中心',
                min_title:'高擎'
            }
        },
        props: {
            collapse: {
                type: Boolean,
                required: true
            }
        },
    }
</script>

<style lang='scss' scoped>
    .sidebarLogoFade-enter-active {
        transition: opacity 1.5s;
    }

    .sidebarLogoFade-enter,
    .sidebarLogoFade-leave-to {
        opacity: 0;
    }

    .sidebar-logo-container {
        position: relative;
        width: 100%;
        height: 50px;
        line-height: 50px;
        background: #fff;
        text-align: center;
        overflow: hidden;

        & .sidebar-logo-link {
            height: 100%;
            width: 100%;

            & .sidebar-logo {
                // width: 32px;
                height: 32px;
                vertical-align: middle;
                // margin-right: 12px;
            }

            & .min-sidebar-logo {
                padding-left: 18px;
                // width: 32px;
                height: 32px;
                vertical-align: middle;
                // margin-right: 12px;
            }

            & .sidebar-title {
                display: inline-block;
                margin: 0;
                color: #fff;
                font-weight: 600;
                line-height: 50px;
                font-size: 14px;
                font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
                vertical-align: middle;
            }
        }

        
        &.collapse {
            .sidebar-logo {
                margin-right: 0px;
            }
        }
    }
</style>