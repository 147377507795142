<template>
    <div class="navbar">
        <!-- <div class="brand">论文评审中心</div> -->
        <!-- Hamburger -->
        <!-- <hamburger id='hamburger-container' :isActive='sidebar.opend' class="hamburger-container" @toggleClick='toggleSideBar'></hamburger> -->
        <div class="title">
            <!-- 鼎新论文送审中心 -->
            <img :src="require('@/assets/img/image.png')" alt="江苏高擎论文评审中心" height="40px;">
        </div>
        <div class="right-menu">
            <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
                <div class="avatar-wrapper">
                    <!-- <img src="~@/assets/img/avatar.png" style="width:40px;height:40px;" class="user-avatar"> -->
                    <span>{{info.institution}} {{info.name}}</span>
                    <i class="el-icon-caret-bottom" />
                </div>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item divided @click.native="logout">
                        <span style="display:block;">退出</span>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
    import Hamburger from './Hamburger'
    import {setToken,getToken,removeToken} from '@/tools/auth'
    export default {
        name: 'Navbar',
        data() {
            return {

            }
        },
        computed:{
            ...mapGetters([
                'sidebar',
                'info'
            ])
        },
        methods: {
            toggleSideBar() {
                this.$store.dispatch('app/toggleSidebar')
            },
            logout() {
                this.$api.user.logout()
                .then(res=>{
                    if(res.data.code == 200)
                    {
                        removeToken();
                        window.localStorage.clear();
                        window.sessionStorage.clear();
                        // this.$store.dispatch('professor/changeIsLogin', 0);
                        this.$router.push(`/login`);
                    }
                })
            }
        },
        components: {
            Hamburger
        }
    }
</script>

<style scope>
    .title{
        float: left;
        font-size: 32px;
        padding-left: 20px;
        line-height: 50px;
        letter-spacing: 5px;
        padding-top: 3px;
        font-family: '楷体';
        color: #fff;
    }
</style>

<style lang='scss' scoped>
    .navbar {
        height: 50px;
        overflow: hidden;
        position: relative;
        // background: #fff;
        background: #bf291b;
        box-shadow: 0 1px 4px rgba(0, 21, 42, 0.08);

        // 汉堡包
        .hamburger-container {
            line-height: 46px;
            height: 100%;
            float: left;
            cursor: pointer;
            transition: background .3s;
            -webkit-tap-highlight-color: transparent;

            &:hover {
                background: rgba(0, 0, 0, .025)
            }
        }

        //右边
        .right-menu {
            float: right;
            height: 100%;
            line-height: 50px;

            &:focus {
                outline: none;
            }

            .right-menu-item {
                display: inline-block;
                padding: 0 8px;
                height: 100%;
                font-size: 18px;
                // color: #5a5e66;
                color: #fff;
                vertical-align: text-bottom;

                &.hover-effect {
                    cursor: pointer;
                    transition: background .3s;

                    &:hover {
                        background: rgba(0, 0, 0, .025)
                    }
                }
            }

            .avatar-container {
                margin-right: 30px;

                // .avatar-wrapper {
                //     // margin-top: 5px;
                //     position: relative;

                //     .user-avatar {
                //         cursor: pointer;
                //         width: 40px;
                //         height: 40px;
                //         border-radius: 10px;
                //     }

                //     .el-icon-caret-bottom {
                //         cursor: pointer;
                //         position: absolute;
                //         // right: -20px;
                //         top: 25px;
                //         font-size: 16px;
                //     }
                // }
            }
        }
    }
</style>