import paperLibrary from "./paperLibrary";
import user from './user'
import expertInfo from "./expertInfo";
import sendManage from "./sendManage";
import reviewIndex from "./reviewIndex";
import file from "./file";

export default {
    paperLibrary,
    user,
    expertInfo,
    sendManage,
    reviewIndex,
    file
}