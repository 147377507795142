const state = {
    rule:{
        insertModel: false
    }
}
const mutations = {
    toggle_ruleInsertModel: (state, value) => {
        state.rule.insertModel = value;
    }
}
const actions = {
    toggle_ruleInsertModel({
        commit
    }, {
        value
    }) {
        commit('toggle_ruleInsertModel', value)
    }
}

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions
}